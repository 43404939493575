<template>
    <div>
        
        <Maintenance></Maintenance>
    </div>
</template>
<script>

import Maintenance from "@core/components/application/Maintenance.vue";

export default {
    components: {
        Maintenance,
    },
    data() {
        return {
        }
    },
    methods: {

    },
    mounted() {
    },

};
</script>
