<template>

    <b-card>
        <b-tabs pills>
            <b-tab active>
                <template #title>
                    <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                    <span class="d-none d-sm-inline">Broadcasting server</span>
                </template>
                <b-row>
                    <!-- <b-col md="4">
                        <b-form-group label="Status">
                            <b-form-input v-model="statusBroadcasting" disabled />
                        </b-form-group>
                    </b-col> -->
                    <!-- <b-col md="4">
                        <b-button @click="isBroadcastingServerRunning()" style="margin-top: 25px;"
                            class="btn btn-success">
                            Test status server
                        </b-button>
                    </b-col>

                    <b-col md="4">
                        <div style="float:right">
                            <span>
                                Port: {{ laravelEchoPort }}
                            </span>
                            <span class="ml-2">
                                Dev site: {{ isDev }}
                            </span>
                        </div>

                    </b-col> -->


                    <b-col md="2">
                        <b-form-group label="Booking id">
                            <b-form-input v-model="bookingId" />
                        </b-form-group>
                    </b-col>

                    <b-col md="3">
                        <b-button @click="getChannelBooking(bookingId)" style="margin-top: 25px;"
                            class="btn btn-warning">
                            Get channel
                        </b-button>
                        <b-button @click="sendDataToChannel(bookingId)" style="margin-top: 25px;"
                            class="btn btn-warning ml-2">
                            Send data
                        </b-button>
                    </b-col>



                    <b-col md="3">
                        <b-form-group label="Channel name">
                            <b-form-input v-model="channelName" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-button @click="reloadFrameBroadcastingClient()" style="margin-top: 25px;"
                            class="btn btn-warning">
                            Set channel
                        </b-button>
                    </b-col>

                    <b-col md="2">
                        <b-button @click="openNoSslPage()" style="margin-top: 25px;"
                            class="btn btn-primary">
                            Open page no ssl
                        </b-button>
                    </b-col>


                    <b-col md="12" v-if="this.isEnableSsl() ==true">
                        <br>
                        <span class="mt-2 mb-2">Listening the channel: {{ this.channelName }}</span>
                        <br>
                        <br>
                        <!-- <div v-for="item in listNotificationFromChannel">
                            {{ item }}
                        </div> -->
                        <div id="broadcastNotifications">

                        </div>

                    </b-col>
                </b-row>
            </b-tab>
            <b-tab>
                <template #title>
                    <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                    <span class="d-none d-sm-inline">SSl certicated</span>
                </template>
                <b-col md="7">
                    <div class="mt-3">
                        <b-form-group label="Ssl expiration date dashboard (yyyy-mm-dd)">
                            <cleave id="date" v-model="dataInfo.ssl_expiration_date_dashboard" class="form-control"
                                :raw="false" :options="options.date" placeholder="YYYY-MM-DD" />
                        </b-form-group>
                        <b-form-group label="Ssl expiration date api (yyyy-mm-dd)">
                            <cleave id="date2" v-model="dataInfo.ssl_expiration_date_api" class="form-control"
                                :raw="false" :options="options.date" placeholder="YYYY-MM-DD" />
                        </b-form-group>
                        <b-button @click="updateSettingsSystem()" style="float: right;" class="btn btn-primary">
                            Save
                        </b-button>
                    </div>

                </b-col>
            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                    <span class="d-none d-sm-inline">Sms messages</span>
                </template>
                <b-row>
                    <b-col md="4">
                        <b-form-group label="Status">
                            <b-form-input v-model="statusSms" disabled />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-button @click="testSms()" style="margin-top: 25px;" class="btn btn-success">
                            Test sms service
                        </b-button>
                    </b-col>
                </b-row>

            </b-tab>
            <b-tab>
                <template #title>
                    <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                    <span class="d-none d-sm-inline">Notifications</span>
                </template>


                <b-col md="7">
                    <div class="mt-3">
                        <div>
                            <table style="width:100%">
                                <tr>
                                    <td style="width:70%">
                                        <b-form-group label="Write a email to notified when a error occurs">
                                            <b-form-input v-model="newEmail" />
                                        </b-form-group>
                                    </td>
                                    <td style="width:30%">
                                        <b-button @click="addNewEmail(newEmail)" style="float: right;"
                                            class="btn btn-success">
                                            Add new email
                                        </b-button>
                                    </td>
                                </tr>
                            </table>
                        </div>


                        <h3 v-if="list_mails_notifications.lenthg > 0">List email</h3>
                        <div v-for="(item, index) in list_mails_notifications" :key="index"
                            style="display: flex;margin-top: 10px;">
                            <b-form-input :value="item" disabled style="margin-right:20px ;" />
                            <b-button @click="deleteEmail(item)" style="float: right;" class="btn btn-danger">
                                Delete
                            </b-button>
                        </div>



                    </div>
                </b-col>
            </b-tab>

        </b-tabs>
    </b-card>

</template>



<script>



import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BTable,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTimepicker,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    BAlert,
    BFormRadio,
    BDropdown,
    BListGroup,
    BListGroupItem,
    BDropdownItem,
    BTab,
    BTabs,
} from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { BCollapse, VBToggle } from "bootstrap-vue";
import Cleave from 'vue-cleave-component'
import { getMessageError } from "@core/utils/utils";
import Echo from 'laravel-echo'


export default {
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BForm,
        BTable,
        BCard,
        BCardText,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        BFormSelect,
        BModal,
        BInputGroup,
        BInputGroupAppend,
        vSelect,
        BFormDatepicker,
        BFormTimepicker,
        FormWizard,
        BTableSimple,
        BThead,
        BTr,
        BTd,
        BTh,
        BTbody,
        BTfoot,
        ValidationProvider,
        ValidationObserver,
        AppCollapse,
        AppCollapseItem,
        BCollapse,
        moment,
        Cleave,
        BAlert,
        BFormRadio,
        BDropdown,
        BListGroup,
        BListGroupItem,
        BDropdownItem,
        BTab,
        BTabs,
    },
    data() {
        return {
            dataInfo: {
                id: null,
                is_live_database: null,
                ssl_expiration_date_dashboard: null,
                ssl_expiration_date_api: null,
                mails_notifications: null,
            },
            list_mails_notifications: [],
            newEmail: "",
            options: {
                date: {
                    date: true,
                    delimiter: '-',
                    datePattern: ['Y', 'm', 'd'],
                },
            },
            statusSms: "UP",
            statusBroadcasting: "UP",
            channelName: "",
            bookingId: "",
            isDev: "",
            laravelEchoPort: "",
            listNotificationFromChannel: [],
            echoCliet: null,
            currentChannel: null,
        };
    },
    methods: {
        async getSettingsSystem() {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`admin/panel/get_info_settings_system`)
                .then((response) => {
                    this.dataInfo = response.data.data;
                    if (this.dataInfo.mails_notifications) {
                        this.list_mails_notifications = this.dataInfo.mails_notifications.split(',');
                    }
                    this.$swal.close();
                })
                .catch((error) => {
                    let message = getMessageError(error);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async updateSettingsSystem() {
            let res = false;
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            res = await this.$http
                .post(
                    `admin/panel/update_settings_system`,
                    this.dataInfo
                )
                .then((res) => {

                    this.$swal({
                        title: "Data was updated successfully",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                    return true;
                })
                .catch((res) => {
                    let message = getMessageError(res);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
            return res;
        },
        async addNewEmail(email) {
            let emailExist = this.list_mails_notifications.find(x => x == email);

            if (emailExist != null) {
                this.$swal({
                    title: "Email already exists",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                });
                return false;
            }
            if (email == "" || email == null) {
                this.$swal({
                    title: "Invalid email",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                });
                return false
            }
            let res = "";
            this.list_mails_notifications.forEach(element => {
                res = res + element + ","
            });

            res = res + email;

            this.dataInfo.mails_notifications = res;
            let isUpdated = await this.updateSettingsSystem();
            if (isUpdated == true) {
                this.getSettingsSystem();
            }
            this.newEmail = null;
        },
        async deleteEmail(email) {
            let res = "";
            this.list_mails_notifications = this.list_mails_notifications.filter(x => x != email);

            this.list_mails_notifications.forEach(element => {
                res = res + element + ","
            });
            res = res.slice(0, -1);

            this.dataInfo.mails_notifications = res;
            let isUpdated = await this.updateSettingsSystem();
            if (isUpdated == true) {
                this.getSettingsSystem();
            }

        },
        async testSms() {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`admin/test_sms_service`)
                .then((response) => {
                    this.statusSms = "UP";
                    this.$swal.close();
                })
                .catch((error) => {
                    let message = getMessageError(error);
                    this.statusSms = "DOWN";
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async GetInfoBroadcastingServer() {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`admin/get_info_broadcasting_server`)
                .then((response) => {
                    this.isDev = response.data.data.isDev;
                    this.laravelEchoPort = response.data.data.laravelEchoPort;
                    this.$swal.close();
                })
                .catch((error) => {
                    let message = getMessageError(error);
                    this.statusSms = "DOWN";
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async isBroadcastingServerRunning() {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`admin/broadcasting_server_isrunning`)
                .then((response) => {
                    let status = response.data.data;
                    if (status == "1") {
                        this.statusBroadcasting = "UP";
                    }

                    if (status == "0") {
                        this.statusBroadcasting = "DOWN";
                    }

                    this.$swal.close();
                })
                .catch((error) => {
                    let message = getMessageError(error);
                    this.statusSms = "DOWN";
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        reloadFrameBroadcastingClient() {

            if (this.isEnableSsl() == false) {
                this.openNoSslPage();
            } else {
                let url = window.location.href.split('/')[0] + "/" + window.location.href.split('/')[1] + "/" + window.location.href.split('/')[2] + "/app-maintenance";

                url += '?bookingId=' + this.bookingId
                url += '&channelName=' + this.channelName

                window.location.href = url;
            }

        },
        openNoSslPage(){
            let urlEnv = process.env.VUE_APP_URL;
                let hostnameBroadcast = urlEnv.split('/')[2];
                let url = "http://" + hostnameBroadcast + "/4a5fds4a5fd464dfs44455fdsa454dfs?channelName=" + this.channelName;
                window.xx=url;
                window.open(url, '_blank');
        },
        listenChannel() {
            this.currentChannel = this.echoCliet.channel(this.channelName);

            this.currentChannel.listen('.bookingTracking', function (data) {

                delete data.socket;
                let span = document.createElement('span');
                span.innerHTML = JSON.stringify(data);
                document.getElementById('broadcastNotifications').appendChild(span);
                let br = document.createElement('br');
                document.getElementById('broadcastNotifications').appendChild(br);
            });
        },
        async getChannelBooking(idBooking) {
            this.$swal({
                title: "Please, wait geting info...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`admin/panel/booking/${idBooking}/info`)
                .then((response) => {
                    this.booking = response.data.data;
                    let selfpayId = this.booking.selfpay_id;
                    let driverId = this.booking.driver_id;
                    let bookingId = this.booking.id;

                    this.channelName = "booking." + bookingId + "." + selfpayId + "." + driverId;

                    if (this.isDev == "yes") {
                        this.channelName = "dev." + this.channelName;
                    }
                    this.$swal.close();
                    this.reloadFrameBroadcastingClient();
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error ",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async loadData() {

            this.getSettingsSystem();
            this.testSms();
            this.isBroadcastingServerRunning();
            await this.GetInfoBroadcastingServer();

            let urlEnv = process.env.VUE_APP_URL;
            let hostnameBroadcast ="https://"+ urlEnv.split('/')[2];

            this.echoCliet = new Echo({
                broadcaster: 'socket.io',
                host: hostnameBroadcast + ":" + this.laravelEchoPort,
            });
            window.xx = this.echoCliet

            if (this.$route.query.bookingId) {
                this.bookingId = this.$route.query.bookingId;
            }
            if (this.$route.query.channelName) {
                this.channelName = this.$route.query.channelName;
            }

            if (this.channelName != null || this.channelName != '') {
                this.listenChannel();
            }

        },
        async sendDataToChannel() {
            let formData = new FormData();
            formData.append("bookingId", this.bookingId);

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .post(
                    `admin/send_tracking_data_driver`,
                    formData
                )
                .then((res) => {
                    this.$swal.close();
                })
                .catch((res) => {
                    let message = getMessageError(res);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        isEnableSsl() {
            let res = false;
            if (location.protocol === 'https:') {
                res = true;
            }
            return res;
        }
    },
    mounted() {
        console.log(4343)
        this.loadData();
    },
};

</script>
